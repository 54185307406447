import { REPORTS_URL } from '@plug/config/env';
import { useState } from 'react';
import axios from 'axios';
import cookies from 'js-cookie';
import { TOKEN_LOCAL_STORAGE_KEY } from '@plug/config/consts';
import { toast } from 'react-toastify';

type Args = {
  onCompleted?: () => void;
  onError?: (error: Error) => void;
};

type BaseFilter = {
  organizationId: string;
  email: string;
};

type Response<T = any> = {
  send: (data: BaseFilter & T) => Promise<void>;
  loading?: boolean;
};

type Report =
  | 'orders'
  | 'insideSales'
  | 'members'
  | 'form'
  | 'linkGenerator'
  | 'unregisteredConsultant';

export function useReport<T>(type: Report, args?: Args): Response<T> {
  const { onCompleted, onError } = args || {};

  const [loading, setLoading] = useState(false);

  const generate = async (body: BaseFilter & T) => {
    setLoading(true);

    try {
      await axios.post(`${REPORTS_URL}/reports/${type}`, body, {
        headers: {
          'x-api-token': cookies.get(TOKEN_LOCAL_STORAGE_KEY),
        },
      });

      if (!onCompleted) {
        toast.success(
          'Seu relatório está sendo processado e será enviado ao seu e-mail em alguns minutos.',
        );

        return;
      }

      return onCompleted?.();
    } catch (e) {
      if (!onError) {
        if (axios.isAxiosError(e)) {
          const message = e.response.data?.message;

          if (message === 'already_precessing_report') {
            toast.error(
              'Já há um relatório sendo processado. Aguarde e tente novamente, por favor.',
            );

            return;
          }
        }

        toast.error('Ocorreu um erro ao gerar o relatório. Tente novamente, por favor.');

        return;
      }

      if (axios.isAxiosError(e)) {
        onError(new Error(e.response.data?.message));
      }

      onError(e);
    } finally {
      setLoading(false);
    }
  };

  return { send: generate, loading };
}
